import { convertToMoney, findColumnIndexes, valueReducer } from '../workWithData';
import {
  normalizeDateOnlyToTimezone,
  normalizeDateRange,
  normalizeDateToTimezone,
  normalizeToTimeOnlyToTimezone,
} from '../datetime';
import { convertCSVColumnsToTextOnDownload } from '../table';
import { OnHoldStatuses } from '../../Order/Utils/constants';
import React from 'react';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import styled from 'styled-components';
import get from 'lodash.get';
import { isOperableArray } from '../other';

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -20px;
  padding-top: 10px;
  margin-bottom: -15px;
`;

const retrieveTrackingNumberFromFulfilmentOrder = (fulfillmentOrder) => {
  if (fulfillmentOrder?.carrier_order) {
    return fulfillmentOrder?.carrier_order?.carrier_tracking_code || '';
  } else {
    return fulfillmentOrder?.wms_tracking_no;
  }
};

export const orderListingDetailTestId = 'order-listing-detail';

export const getCustomerName = (address) => address && `${address.first_name} ${address.last_name}`;

export const getTableFilters = (filters, columns) =>
  filters.map((filter, index) => {
    return {
      column: columns[index]?.column || columns[index].name,
      value: filter,
    };
  });

export const getOrderListingRequestParams = (activeFilters, activeDateFilter) => ({
  country: activeFilters.marketplace_country,
  destination_state: activeFilters.destination_state,
  destination_city: activeFilters.destination_city,
  payment_method: activeFilters.payment_method,
  marketplace_connection_id: activeFilters.connection_id,
  fulfilment_status: Array.isArray(activeFilters?.fulfilment_status)
    ? activeFilters?.fulfilment_status?.join(',')
    : activeFilters?.fulfilment_status,
  shipment_status: activeFilters.shipment_status,
  marketplace_status: activeFilters.marketplace_status,
  has_waybill: activeFilters.has_waybill,
  is_draft: activeFilters.is_draft,
  is_edited: activeFilters.is_edited,
  fulfilled_by: activeFilters.fulfilled_by,
  cod_remittance_status: activeFilters.cod_remittance_status,
  start_date: activeDateFilter?.start,
  end_date: activeDateFilter?.end,
});

export const getOrderListingFiltersRequestParams = (activeFilters, activeDateFilter) => ({
  country: activeFilters.country,
  start_date: activeDateFilter?.start,
  end_date: activeDateFilter?.end,
});

export const getMarketplaceFilters = (filters, disabled) => {
  const cardFilters = filters
    ? filters.map((f) => ({
        label: f.connection_name,
        value: f.connection_id,
        count: f.count,
        connectionType: f.connection_type,
        disabled,
      }))
    : [];

  return cardFilters.length
    ? [
        {
          label: 'All Marketplaces',
          value: null,
          count: filters.reduce(valueReducer('count'), 0),
          disabled,
        },
        ...cardFilters,
      ]
    : [];
};

export const ORDERING_MAPPING = {
  order_number_data: 'marketplace_order_id',
  marketplace_country: 'marketplace__country__country_name',
  marketplace_name: 'marketplace__name',
  order_items: 'total_items',
  total_price_data: 'total_price',
  customer_name: 'shipping_address__first_name,shipping_address__last_name',
  order_date: 'marketplace_create_time,shipping_address__last_name',
};

export const prepareOrderItemToRender = (fulfillmentOrders, isTrackingEnabled) => (item) => {
  const additionalValues = {
    total_price: convertToMoney(item?.price, item?.currency_symbol),
    marketplace_tracking_detail: {
      url: Boolean(isTrackingEnabled) ? get(item, 'locad_tracking_url', null) : get(item, 'tracking_url', null),
      tracking_code: item?.marketplace_tracking_code,
    },
    marketplace_statuses_data: {
      statuses: item?.marketplace_status || '',
      cancellation_reason: item?.marketplace_cancel_reason?.trim() || '',
      should_fulfil: item?.should_fulfil,
      non_fulfilable_reason: item?.non_fulfilable_reason,
    },
  };

  if (item.fulfilment_order) {
    const order = fulfillmentOrders?.find((o) => o.id === item.fulfilment_order);
    additionalValues.fulfillment_order_number = order?.wms_forder_id;
    additionalValues.fulfilment_status = order?.wms_status;
    additionalValues.shipment_status = order?.shipment_status;
    additionalValues.fulfilment_tracking_number = order?.wms_tracking_no;
    additionalValues.waybill_fetch_error = order?.waybill_fetch_error;
    additionalValues.marketplace_statuses_data.out_of_sync = order?.marketplace_out_of_sync || false;
    additionalValues.marketplace_statuses_data.status_change_error = order?.marketplace_status_change_error || '';
    additionalValues.marketplace_statuses_data.tracking_info_change_error =
      order?.marketplace_tracking_info_change_error || '';

    additionalValues.warehouse_statuses_data = order
      ? {
          statuses: order.wms_status || '',
          cancellation_reason: order.forder_cancel_reason?.trim() || '',
          on_hold_reason: order.on_hold_reason || '',
          is_on_hold: OnHoldStatuses.includes(order.wms_status?.toLowerCase()),
        }
      : {};
    additionalValues.shipment_statuses_data = order
      ? {
          statuses: order.carrier_order?.carrier_order_status || '',
        }
      : {};
    additionalValues.carrier_order = order?.carrier_order || null;
  }

  return {
    ...item,
    ...additionalValues,
  };
};

const cancelledStatuses = ['CANCELLED', 'CANCELLATION_IN_PROGRESS', 'PUTAWAY'];

const getSlaTimesDateCell = (dateKey) => (forders) => {
  const sla_items = forders
    ?.filter((i) => i.sla_times)
    .filter((fo) => !cancelledStatuses.includes(fo.wms_status))?.[0]?.sla_times;
  return sla_items?.[dateKey] ? normalizeDateToTimezone(sla_items[dateKey]) : '-';
};

const formatDate = (dateValue) => {
  return dateValue && dateValue.length >= 10 ? dateValue.substring(0, 10) : dateValue;
};

const getDateCell = (dateKey) => (forders) => {
  return (
    forders
      ?.filter((o) => o[dateKey])
      .map((o) => o[dateKey])
      .map(formatDate)
      ?.join(',') || ''
  );
};

const getFulfilledDate = getSlaTimesDateCell('ready_for_dispatch_at');
const getShippedDate = getSlaTimesDateCell('dispatched_at');
const getDeliveredDate = getSlaTimesDateCell('completed_at');
const getWHReleaseDate = getDateCell('release_date');
const getEstimatedDeliveryDate = getDateCell('estimated_delivery_date');

const OrderDateRender = ({ date }) => {
  const tableBreak = useMediaQuery('(max-width: 960px)');

  const dateOnly = normalizeDateOnlyToTimezone(date);
  const timeOnly = normalizeToTimeOnlyToTimezone(date);

  return (
    <div style={{ display: tableBreak ? 'inline-flex' : 'block', ...(tableBreak && { gap: '5px' }) }}>
      <div>{dateOnly}</div>
      <div>{timeOnly}</div>
    </div>
  );
};

export const prepareOrderListingItemToRender = (item) => {
  const lastForder = item.fulfilment_orders?.length ? item.fulfilment_orders[item.fulfilment_orders.length - 1] : null;
  return {
    ...item,
    customer_name: getCustomerName(item.shipping_address),
    marketplace_name: item.marketplace?.name,
    connection_name: item?.marketplace_connection?.connection_name,
    marketplace_country: item.marketplace?.country,
    shipment_type: item.fulfilment_orders?.map((o) => o.shipment_type) || '',
    on_hold_reasons: item.fulfilment_orders?.map((o) => o.on_hold_reason)?.join(', ') || '',
    marketplace_statuses_data: {
      statuses: item.marketplace_status || '',
      cancellation_reason: item.marketplace_cancellation_reasons.filter((o) => o.trim() !== '').join(', ') || '',
      any_non_fulfil_item: item?.any_non_fulfil_item,
    },
    warehouse_statuses: item.fulfilment_orders?.map((o) => o.wms_status) || '',
    warehouse_statuses_data: {
      statuses: item.fulfilment_orders?.map((o) => o.wms_status) || '',
      cancellation_reason: item.wms_cancellation_reasons.filter((o) => o.trim() !== '').join(', ') || '',
      on_hold_reason: item.fulfilment_orders?.map((o) => o.on_hold_reason) || [],
      is_on_hold: item.fulfilment_orders?.find((o) => OnHoldStatuses.includes(o.wms_status?.toLowerCase())),
    },
    shipment_statuses: item.fulfilment_orders?.map((o) => o.carrier_order?.carrier_order_status) || '',
    shipment_statuses_data: {
      statuses: item.fulfilment_orders?.map((o) => o.carrier_order?.carrier_order_status) || '',
    },
    order_number_data: {
      id: item.id,
      value: item.marketplace_order_id,
      is_gift: item.is_gift,
    },
    total_price_data: convertToMoney(item.total_price, item.currency_symbol),
    order_date: () => <OrderDateRender date={item.marketplace_create_time} />,
    ready_for_dispatch_at: getFulfilledDate(item.fulfilment_orders),
    dispatched_at: getShippedDate(item.fulfilment_orders),
    completed_at: getDeliveredDate(item.fulfilment_orders),
    delivery_date: formatDate(item.delivery_date),
    release_date: getWHReleaseDate(item.fulfilment_orders),
    estimated_delivery_date: getEstimatedDeliveryDate(item.fulfilment_orders),
    carrier_name: lastForder?.shipment_provider?.name,
    tracking_number: retrieveTrackingNumberFromFulfilmentOrder(lastForder),
    contact_number: item.shipping_address?.phone,
    cod_remittance_status: item?.fulfilment_orders?.map((o) => o?.cod_remittance?.status) || '',
    remitted_by_carrier: item?.fulfilment_orders?.map((o) => o?.cod_remittance?.remitted_by_carrier) || '',
    remitted_by_locad: item?.fulfilment_orders?.map((o) => o?.cod_remittance?.remitted_by_locad) || '',
    carrier_remit_days: item?.fulfilment_orders?.map((o) => o?.cod_remittance?.carrier_remit_days) || null,
    locad_remit_days: item?.fulfilment_orders?.map((o) => o?.cod_remittance?.locad_remit_days) || null,
    cod_live_date: item?.fulfilment_orders?.map((o) => o?.cod_remittance?.cod_live_date) || null,
  };
};

export const prepareOrderItemsListingItemToRender = (item) => {
  const fulfilment_order_item = item.fulfilment_orders.filter((o) => o.id === item?.order_item.fulfilment_order)[0];
  return {
    ...item,
    order_item_id: item?.order_item.order_item_id,
    catalogue_name: item?.order_item?.catalogue_product?.name,
    catalogue_sku: item?.order_item?.catalogue_product?.marketplace_product_sku,
    customer_name: getCustomerName(item.shipping_address),
    marketplace_name: item.marketplace?.name,
    marketplace_country: item.marketplace?.country,
    marketplace_item_status: item?.order_item.marketplace_status,
    marketplace_item_cancel_reason: item?.order_item?.marketplace_cancel_reason,
    warehouse_status: fulfilment_order_item?.wms_status,
    shipment_status: fulfilment_order_item?.shipment_status,
    fulfilment_order_cancel_reason: fulfilment_order_item?.forder_cancel_reason,
    on_hold_reason: fulfilment_order_item?.on_hold_reason,
    item_price: item?.order_item?.price,
    order_item_quantity: item?.order_item.purchase_quantity,
    carrier_name: fulfilment_order_item?.carrier_order?.carrier_connection?.carrier?.name,
    tracking_number: fulfilment_order_item?.carrier_order?.carrier_tracking_code,
    contact_number: item?.shipping_address?.phone,
    order_date: normalizeDateToTimezone(item.marketplace_create_time),
    ready_for_dispatch_at: normalizeDateToTimezone(fulfilment_order_item?.sla_times?.ready_for_dispatch_at),
    dispatched_at: normalizeDateToTimezone(fulfilment_order_item?.sla_times?.dispatched_at),
    completed_at: normalizeDateToTimezone(fulfilment_order_item?.sla_times?.completed_at),
    release_date: normalizeDateToTimezone(fulfilment_order_item?.release_date),
    estimated_delivery_date: normalizeDateToTimezone(fulfilment_order_item?.estimated_delivery_date),
  };
};

export const createInfoMessagesForCancellationReason = (reason) => {
  return reason ? { title: reason, color: 'error', icon: 'Info' } : null;
};

export const createNonFulfilableInfoMessage = (title) => {
  return title ? { title, color: 'warning', icon: 'WorkOff' } : null;
};

const createMultilineNestedHTMLMessage = (objlist = [], title) => {
  let msg = [];
  msg.push(
    <Typography>
      {title === 'Waybill Fetch Error' ? (
        <HeadWrapper>
          <CancelIcon color="error" style={{ marginRight: 10 }} />
          <h3>{title}</h3>
        </HeadWrapper>
      ) : title === 'Passed Check' ? (
        <HeadWrapper>
          <CheckCircleIcon color="secondary" style={{ marginRight: 10 }} />
          <h3>{title}</h3>
        </HeadWrapper>
      ) : title === 'Failed Check' ? (
        <HeadWrapper>
          <CancelIcon color="error" style={{ marginRight: 10 }} />
          <h3>{title}</h3>
        </HeadWrapper>
      ) : title === 'Not Tested' ? (
        <HeadWrapper>
          {isOperableArray(objlist) ? (
            <>
              <RemoveCircleIcon color="neutral" style={{ marginRight: 10 }} />
              <h3>{title}</h3>
            </>
          ) : null}
        </HeadWrapper>
      ) : null}
    </Typography>
  );

  isOperableArray(objlist) &&
    objlist.forEach((obj) => {
      if (isOperableArray(obj.messages)) {
        msg.push(
          <React.Fragment>
            <Typography style={{ margin: '0 0 -2px 30px' }}>
              <li dangerouslySetInnerHTML={{ __html: obj.name }} />
            </Typography>
            <ul style={{ marginTop: '-2px' }}>{createMultilineNestedHTMLMessage(obj.messages)}</ul>
          </React.Fragment>
        );
      } else {
        msg.push(
          <React.Fragment>
            <Typography style={{ margin: '0 0 -2px 30px' }}>
              <li dangerouslySetInnerHTML={{ __html: obj.name }} />
            </Typography>
          </React.Fragment>
        );
      }
    });

  return msg;
};

export const createWaybillFetchError = (jsonList) => {
  let on_hold_message = (
    <Box style={{ marginBottom: '15px' }}>{createMultilineNestedHTMLMessage(jsonList, 'Waybill Fetch Error')}</Box>
  );
  return on_hold_message
    ? {
        title: on_hold_message,
        color: 'warning',
        icon: 'AssignmentLate',
        width: '500px',
      }
    : null;
};

export const createOhHoldInfoMessage = (jsonList) => {
  let on_hold_message = (
    <Box style={{ marginBottom: '15px' }}>
      {createMultilineNestedHTMLMessage(jsonList.failed_check, 'Failed Check')}
    </Box>
  );
  return on_hold_message
    ? {
        title: on_hold_message,
        color: 'warning',
        icon: 'AssignmentLate',
        width: '500px',
      }
    : null;
};

// save shipped/dispatched/delivered date columns visibility to localStorage
export const getAdditionalDateColumnsVisibility = () => ({
  ready_for_dispatch_at: JSON.parse(localStorage.getItem('is_ready_for_dispatch_at_visible')) || false,
  dispatched_at: JSON.parse(localStorage.getItem('is_dispatched_at_visible')) || false,
  completed_at: JSON.parse(localStorage.getItem('is_completed_at_visible')) || false,
  release_date: JSON.parse(localStorage.getItem('is_release_date_visible')) || false,
  estimated_delivery_date: JSON.parse(localStorage.getItem('is_estimated_delivery_date_visible')) || false,
});

export const setAdditionalDateColumnVisibility = (name, value) => {
  localStorage.setItem(`is_${name}_visible`, value);
};

export const getSearchQueryFromLocation = (location) => {
  return location?.search ? new URLSearchParams(location.search) : null;
};

export const getActiveFiltersInitialState = (
  searchQuery,
  filtersFromLocationState,
  defaultDateRangeFilter,
  filters
) => {
  return {
    country: searchQuery?.get('country') || null,
    payment_method: null,
    connection_id: null,
    marketplace_status: null,
    has_waybill: null,
    is_draft: null,
    is_edited: null,
    fulfilled_by: null,
    dateRange: filtersFromLocationState?.dateRange ? filtersFromLocationState.dateRange : defaultDateRangeFilter,
    fulfilment_status: filtersFromLocationState?.fulfilment_status || null,
    shipment_status: filtersFromLocationState?.shipment_status || null,
  };
};

export const getFiltersFromLocationState = (location) => {
  if (location?.state) {
    const { start_date, end_date, status, last_mile_status } = location.state;

    return {
      dateRange:
        start_date && end_date
          ? {
              value: 'Custom',
              disabled: true,
              start: start_date,
              end: end_date,
            }
          : null,
      fulfilment_status: status || null,
      shipment_status: last_mile_status || null,
    };
  } else {
    return null;
  }
};

export const onOrderListingDownload = convertCSVColumnsToTextOnDownload('name', ['marketplace_order_id']);

export const COL_NAME_TO_FILTER_MAP = {
  warehouse_statuses: 'fulfilment_status',
  shipment_statuses: 'shipment_status',
  payment_method: 'payment_method',
  marketplace_status: 'marketplace_status',
  has_waybill: 'has_waybill',
  marketplace_create_time: 'dateRange',
  fulfilled_by: 'fulfilled_by',
  marketplace_country: 'marketplace_country',
  destination_state: 'destination_state',
  destination_city: 'destination_city',
  cod_remittance_status: 'cod_remittance_status',
  is_draft: 'is_draft',
  is_edited: 'is_edited',
};

export const getActiveFiltersFromTableFilterList = (filterList, columns, globalDateFilter) => {
  const newActiveFilters = {};
  const [
    WarehouseStatusFiltersIndex,
    ShipmentStatusFiltersIndex,
    paymentMethodFilterIndex,
    marketplaceStatusIndex,
    fulfilledByStatusIndex,
    marketplaceCountryFilterIndex,
    destinationStateFilterIndex,
    destinationCityFilterIndex,
    codRemittanceStatusFilterIndex,
    hasWaybillIndex,
    isDraftIndex,
    isEditedIndex,
  ] = findColumnIndexes(columns, 'name', [
    'warehouse_statuses',
    'shipment_statuses',
    'payment_method',
    'marketplace_status',
    'fulfilled_by',
    'marketplace',
    'destination_state',
    'destination_city',
    'cod_remittance_status',
    'has_waybill',
    'is_draft',
    'is_edited',
  ]);
  if (WarehouseStatusFiltersIndex >= 0 && filterList[WarehouseStatusFiltersIndex].length) {
    newActiveFilters[COL_NAME_TO_FILTER_MAP['warehouse_statuses']] = filterList[WarehouseStatusFiltersIndex].join();
  }
  if (ShipmentStatusFiltersIndex >= 0 && filterList[ShipmentStatusFiltersIndex].length) {
    newActiveFilters[COL_NAME_TO_FILTER_MAP['shipment_statuses']] = filterList[ShipmentStatusFiltersIndex].join();
  }
  if (paymentMethodFilterIndex >= 0 && filterList[paymentMethodFilterIndex].length) {
    newActiveFilters[COL_NAME_TO_FILTER_MAP['payment_method']] = filterList[paymentMethodFilterIndex].join();
  }
  if (marketplaceStatusIndex >= 0 && filterList[marketplaceStatusIndex].length) {
    newActiveFilters[COL_NAME_TO_FILTER_MAP['marketplace_status']] = filterList[marketplaceStatusIndex].join();
  }
  if (fulfilledByStatusIndex >= 0 && filterList[fulfilledByStatusIndex].length) {
    newActiveFilters[COL_NAME_TO_FILTER_MAP['fulfilled_by']] = filterList[fulfilledByStatusIndex].join();
  }
  if (hasWaybillIndex >= 0 && filterList[hasWaybillIndex].length) {
    newActiveFilters[COL_NAME_TO_FILTER_MAP['has_waybill']] = filterList[hasWaybillIndex].join();
  }
  if (isDraftIndex >= 0 && filterList[isDraftIndex].length) {
    newActiveFilters[COL_NAME_TO_FILTER_MAP['is_draft']] = filterList[isDraftIndex].join();
  }
  if (isDraftIndex >= 0 && filterList[isEditedIndex].length) {
    newActiveFilters[COL_NAME_TO_FILTER_MAP['is_edited']] = filterList[isEditedIndex].join();
  }
  if (marketplaceCountryFilterIndex >= 0 && filterList[marketplaceCountryFilterIndex].length) {
    newActiveFilters[COL_NAME_TO_FILTER_MAP['marketplace_country']] = filterList[marketplaceCountryFilterIndex].join();
  }
  if (destinationStateFilterIndex >= 0 && filterList[destinationStateFilterIndex].length) {
    newActiveFilters[COL_NAME_TO_FILTER_MAP['destination_state']] = filterList[destinationStateFilterIndex].join();
  }
  if (destinationCityFilterIndex >= 0 && filterList[destinationCityFilterIndex].length) {
    newActiveFilters[COL_NAME_TO_FILTER_MAP['destination_city']] = filterList[destinationCityFilterIndex].join();
  }
  if (codRemittanceStatusFilterIndex >= 0 && filterList[codRemittanceStatusFilterIndex].length) {
    newActiveFilters[COL_NAME_TO_FILTER_MAP['cod_remittance_status']] =
      filterList[codRemittanceStatusFilterIndex].join();
  }
  return newActiveFilters;
};

export function flattenObject(array) {
  var result = [];
  array.forEach(function (obj) {
    let finalObj = {};
    flat(obj, '', finalObj);
    result.push(finalObj);
  });
  return result;
}

function flat(obj, parent, finalObj) {
  for (let key in obj) {
    let parentKey = parent ? parent + '_' + key : key;
    if (typeof obj[key] === 'object') {
      flat(obj[key], parentKey, finalObj);
    } else {
      finalObj[parentKey] = obj[key];
    }
  }
}

export function groupFilterData(filterList, tableColumns) {
  let tempFilters = {};
  tableColumns.forEach(function (column, index) {
    let validFilter = Array.isArray(filterList[index]) && filterList[index].length > 0 ? true : false;
    switch (column.name) {
      case 'marketplace_name':
        tempFilters['marketplace_name'] = validFilter ? filterList[index].toString() : [];
        break;
      case 'order_date':
        tempFilters['start_date'] = validFilter && filterList[index].length > 1 ? filterList[index][0] : [];
        tempFilters['end_date'] = validFilter && filterList[index].length > 1 ? filterList[index][1] : [];
        break;
      default:
        tempFilters[column.name] = validFilter ? filterList[index].toString() : [];
        break;
    }
  }, this);
  return removeEmptyFilters(tempFilters);
}

function removeEmptyFilters(filtersList) {
  Object.filter = (obj, predicate) =>
    Object.keys(obj)
      .filter((key) => predicate(obj[key]))
      .reduce((res, key) => {
        res[key] = obj[key];
        return res;
      }, {});
  return Object.filter(filtersList, (filter) => filter.length > 0);
}

export function parseSortOrderParam(sortOrderParams) {
  let sortOrder = {};
  switch (sortOrderParams.name) {
    case 'customer_first_name':
      sortOrder['ordering'] =
        sortOrderParams.direction === 'desc'
          ? '-customer_first_name,-customer_last_name'
          : 'customer_first_name,customer_last_name';
      break;
    default:
      sortOrder['ordering'] = sortOrderParams.direction === 'desc' ? `-${sortOrderParams.name}` : sortOrderParams.name;
      break;
  }
  return sortOrder;
}

export function parseFilters(filters) {
  let newFilters = {};
  Object.keys(filters).forEach((key) => {
    switch (key) {
      case 'start_date':
        newFilters[key] = normalizeDateRange(filters[key], 'start');
        break;
      case 'end_date':
        newFilters[key] = normalizeDateRange(filters[key], 'end');
        break;
      default:
        newFilters[key] = filters[key];
        break;
    }
  });
  return newFilters;
}

export const updateColumnCustomBodyRenderRender = (column) => (customBodyRender) => ({
  ...column,
  options: {
    ...column.options,
    customBodyRender,
  },
});
